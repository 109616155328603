<template>
<!--== Start Product Quick Wishlist Modal ==-->
<aside class="product-action-modal modal fade" id="action-WishlistModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="product-action-view-content">
                    <div class="d-flex align-items-center">
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                            <i class="icofont-close"></i>
                        </button>
                        <div class="modal-action-messages">
                            <i class="fa fa-check-square-o"></i> Added to wishlist successfully!
                        </div>
                    </div>
                    <div class="modal-action-product">
                        <div class="thumb">
                            <img src="assets/images/shop/1.jpg" alt="Organic Food Juice" width="466" height="320">
                        </div>
                        <h4 class="product-name"><a href="single-product.html">Product with video</a></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</aside>
<!--== End Product Quick Wishlist Modal ==-->
</template>

<script>
export default {
name:"ProductQuickWishlist"
}
</script>

<style>

</style>