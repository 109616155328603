<template>
          <!--== Start Footer Area Wrapper ==-->
          <footer class="footer-two-area">
            <!--== Start Footer Main ==-->
            <div class="footer-main">
                <div class="container">
                    <div class="row mb-n6">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="widget-item">
                                <h4 class="widget-title">About Info</h4>
                                <h4 class="widget-title widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetTitleId-4">About Info</h4>
                                <div id="widgetTitleId-4" class="collapse widget-collapse-body">
                                    <div class="widget-about">
                                        <p class="widget-about-desc text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit <br class="d-none d-xl-block">sed do eiusmod tempor inci ut labore et dolore.</p>
                                    </div>
                                    <div class="widget-contact">
                                        <div class="widget-info-item"><i class="icon icon-location-pin"></i> Addresss: 123 Pall Mall, London England</div>
                                        <div class="widget-info-item"><i class="icon icon-envelope"></i> Email: hello@example.com</div>
                                        <div class="widget-info-item"><i class="icon icon-phone"></i> Phone: (012) 345 6789</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-12 mt-4 mt-md-0">
                            <div class="widget-item">
                                <h4 class="widget-title">Quick Links</h4>
                                <h4 class="widget-title widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetTitleId-1">Quick Links</h4>
                                <div id="widgetTitleId-1" class="collapse widget-collapse-body">
                                    <ul class="widget-nav">
                                        <li><a href="account.html">My account</a></li>
                                        <li><a href="about-us.html">About us</a></li>
                                        <li><a href="contact.html">Returns</a></li>
                                        <li><a href="contact.html">Privacy Policy</a></li>
                                        <li><a href="contact.html">Site Map</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-12 mt-4 mt-md-6 mt-lg-0">
                            <div class="widget-item">
                                <h4 class="widget-title">Information</h4>
                                <h4 class="widget-title widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetTitleId-2">Information</h4>
                                <div id="widgetTitleId-2" class="collapse widget-collapse-body">
                                    <ul class="widget-nav">
                                        <li><a href="shop.html">New collection</a></li>
                                        <li><a href="shop.html">Manufacturers</a></li>
                                        <li><a href="shop.html">New products</a></li>
                                        <li><a href="shop.html">Suppliers</a></li>
                                        <li><a href="shop.html">Delivery & returns</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-12 mt-4 mt-md-6 mt-lg-0">
                            <div class="widget-item">
                                <h4 class="widget-title">Follow Us On</h4>
                                <h4 class="widget-title widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetTitleId-3">Follow Us On</h4>
                                <div id="widgetTitleId-3" class="collapse widget-collapse-body">
                                    <ul class="widget-social-list">
                                        <li><a href="https://www.facebook.com/" target="_blank" rel="noopener"><i class="icon icon-social-facebook"></i> Facebook</a></li>
                                        <li><a href="https://www.twitter.com/" target="_blank" rel="noopener"><i class="icon icon-social-twitter"></i> Twitter</a></li>
                                        <li><a href="https://www.instagram.com/" target="_blank" rel="noopener"><i class="icon icon-social-instagram"></i> Instagram</a></li>
                                        <li><a href="https://www.youtube.com/" target="_blank" rel="noopener"><i class="icon icon-social-youtube"></i> Youtube</a></li>
                                        <li><a href="https://www.pinterest.com/" target="_blank" rel="noopener"><i class="icon icon-social-pinterest"></i> Pinterest</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--== End Footer Main ==-->

            <!--== Start Footer Bottom ==-->
            <div class="footer-bottom">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6 col-12 text-center text-md-start mt-3 mt-md-0 order-1 order-md-0">
                            <p class="copyright text-white">© 2023 Dkoor. Made with <i class="icofont-heart-alt"></i> by <a target="_blank" href="https://themeforest.net/user/bootxperts/portfolio">BootXperts</a></p>
                        </div>
                        <div class="col-md-6 col-12 text-center text-md-end order-0 order-md-1 mt-1 mt-md-0">
                            <a href="shop.html"><img src="assets/images/shop/payment.png" alt="Image-HasTech"></a>
                        </div>
                    </div>
                </div>
            </div>
            <!--== End Footer Bottom ==-->

        </footer>
        <!--== End Footer Area Wrapper ==-->
</template>

<script>
export default {
name:"footerComponent"
}
</script>

<style>

</style>