<template>

            <!--== Start Header Wrapper ==-->
            <header class="header-wrapper">
            <div class="header-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-2 col-lg-2 col-6">
                            <div class="header-logo">
                                <a href="index.html">
                                    <img class="logo-main" src="assets/images/logo.png" width="125" height="25" alt="Logo">
                                </a>
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-7 d-none d-lg-block">
                            <div class="header-navigation">
                                <ul class="main-nav">
                                    <li class="main-nav-item has-submenu"><router-link class="main-nav-link" to="/">Home</router-link>
                                    </li>
                                    <li class="main-nav-item has-submenu"><router-link class="main-nav-link" to="blogs">Blog</router-link>
                                        <!-- <ul class="submenu-nav">
                                            <li class="submenu-nav-item"><a class="submenu-nav-link" href="blog.html">Blog Grid</a></li>
                                            <li class="submenu-nav-item"><a class="submenu-nav-link" href="blog-left-sidebar.html">Blog Left Sidebar</a></li>
                                            <li class="submenu-nav-item"><a class="submenu-nav-link" href="blog-right-sidebar.html">Blog Right Sidebar</a></li>
                                            <li class="submenu-nav-item"><a class="submenu-nav-link" href="blog-details.html">Blog Details</a></li>
                                            <li class="submenu-nav-item"><a class="submenu-nav-link" href="blog-details-left-sidebar.html">Blog Details Left Sidebar</a></li>
                                            <li class="submenu-nav-item"><a class="submenu-nav-link" href="blog-details-right-sidebar.html">Blog Details Right Sidebar</a></li>
                                        </ul> -->
                                    </li>
                                    <li class="main-nav-item has-submenu"><router-link class="main-nav-link" to="shop">Shop</router-link>
                                        <ul class="submenu-nav">
                                            <li class="submenu-nav-item"><a class="submenu-nav-link" href="blog.html">Shopping Cart</a></li>
                                            <li class="submenu-nav-item"><a class="submenu-nav-link" href="blog-left-sidebar.html">Checkout</a></li>
                                            <li class="submenu-nav-item"><a class="submenu-nav-link" href="blog-right-sidebar.html">Wishlist</a></li>
                                        </ul>
                                    </li>
                                    
                                    <li class="main-nav-item"><router-link class="main-nav-link" to="/contact">Contact</router-link></li>
                                    <li class="main-nav-item"><a class="main-nav-link" href="about-us.html">About</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-6">
                            <div class="header-action d-flex align-items-center justify-content-end">
                                <button class="btn-search-menu d-none d-lg-block" type="button" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasSearch" aria-controls="AsideOffcanvasSearch">
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-search">
                                        <path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z" class=""></path>
                                    </svg>
                                </button>
                                <button class="header-action-cart" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithCartSidebar" aria-controls="offcanvasWithCartSidebar">
                                    <i class="cart-icon icofont-cart"></i>
                                    <span class="cart-amount">$58.00</span>
                                    <span class="cart-count">2</span>
                                </button>
                                <div class="header-info-dropdown d-none d-lg-block">
                                    <button type="button" class="btn-info dropdown-toggle" id="dropdownCurrency" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="icofont-gear-alt"></i>
                                    </button>
                                    <div class="dropdown-menu header-dropdown-menu" aria-labelledby="dropdownCurrency">
                                        <h6 class="header-dropdown-menu-title">Currency</h6>
                                        <ul>
                                            <li><a href="javascript:void(0)">USD - US Dollar</a></li>
                                            <li><a href="javascript:void(0)">EUR - Euro</a></li>
                                            <li><a href="javascript:void(0)">GBP - British Pound</a></li>
                                        </ul>
                                        <h6 class="header-dropdown-menu-title style-two">Account</h6>
                                        <ul>
                                            <li><a href="login-register.html">Login</a></li>
                                            <li><a href="login-register.html">Register</a></li>
                                            <li><a href="account.html">My Account</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <button class="btn-menu-two d-lg-none me-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                                    <i class="icon-menu"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!--== End Header Wrapper ==-->

</template>

<script >
export default {
name:"navBar",
data(){

}
}
</script>

<style scoped>
a:hover{
color: #d84f2a;
}


</style>