<template>
    <div class="product-banner-area section-space">
                <div class="container">
                    <div class="row gx-2 mb-n6">
                        <div class="col-md-6 col-12 mb-4" v-for="bannerItem in bannerItems" :key="bannerItem">
                            <!--== Start Product Banner Item ==-->
                            <div class="product-banner-item">
                                <div class="product-banner-thumb">
                                    <a :href="bannerItem.link"><img :src="bannerItem.imageSrc" width="580" height="243" alt="Image-HasTech"></a>
                                </div>
                            </div>
                            <!--== End Product Banner Item ==-->
                        </div>
                    </div>
                </div>
            </div>
            <!--== End Product Banner Area Wrapper ==-->
</template>

<script>
export default {
name:"productBanner",
data(){
    return {
        bannerItems:[
            {
                link:"",
                imageSrc:"assets/images/home/2.png"
            },
            {
                link:"",
                imageSrc:"assets/images/home/1.png"
            }
        ]
    }
}
}
</script>

<style>

</style>