<template>
    <div class="product-area section-bottom-space position-relative">
                <div class="container">
                    <div class="section-title text-center mb-0">
                        <h5 class="sub-title text-uppercase text-primary mt-n1">Explore The Awesome</h5>
                        <h2 class="title mb-0 mt-n2">Various Products</h2>
                    </div>

                    <div class="product-tab-content">
                        <ul class="nav nav-tabs nav-tabs-two" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="new-products-tab" data-bs-toggle="tab" data-bs-target="#new-products" type="button" role="tab" aria-controls="new-products" aria-selected="true">New Products</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="best-selling-product-tab" data-bs-toggle="tab" data-bs-target="#best-selling-product" type="button" role="tab" aria-controls="best-selling-product" aria-selected="false">Best Selling</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="accessories-product-tab" data-bs-toggle="tab" data-bs-target="#accessories-product" type="button" role="tab" aria-controls="accessories-product" aria-selected="false">Accessories</button>
                            </li>
                        </ul>

                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="new-products" role="tabpanel" aria-labelledby="new-products-tab">
                                <div class="swiper-container-wrap swiper-button-style swiper-button-nav10 mb-n6">
                                    <!--== Start Swiper Navigation ==-->
                                    <div class="swiper-button-prev"></div>
                                    <div class="swiper-button-next"></div>

                                    <div class="swiper product-tab-four-grid-slider">
                                        <div class="swiper-wrapper">

                                            <div  v-for="(product,index) in showedProducts" :key="product" class="swiper-slide">
                                                <!--== Start Product Item ==-->
                                                <div class="product-item mb-4" >
                                                    <div class="product-item-thumb-wrap">
                                                        <div class="tab-content product-item-tab-content" id="productItem10-tabContent">
                                                            <div

                                                                 class="tab-pane fade show active "
                                                                 id="product-item128"
                                                                 role="tabpanel" 
                                                                 aria-labelledby="product-item128-tab"
                                                                 >
                                                                <a class="product-item-thumb" href="shop-single-product.html">
                                                                    <img :src="product.currentImage" width="268" height="313" alt="Image-HasTech">
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="nav nav-tabs product-item-tab-btn" id="productItem10-tab" role="tablist">
                                                            <button         
                                                             class="nav-link " id="product-item128-tab"
                                                             data-bs-toggle="tab"
                                                             data-bs-target="#product-item128"
                                                             type="button" role="tab"
                                                             aria-controls="product-item128" 
                                                             
                                                             :aria-selected="{}"
                                                             v-for="(image,index) in product.images"
                                                             :key="index"
                                                             :data-bg-img="image"
                                                             @click="()=>{
                                                                product.currentImage=image
                                                             }" 
                                                             >
                                                             </button>
                                                        </div>
                                                        <div v-if="product.sale.hasPromotion">
                                                        <span class="label">Sale</span>
                                                        <span class="label label-two">-{{product.sale.percentageOfPromotion}}%</span>
                                                        </div>
                                                        <!-- <span class="label">Sale</span>
                                                        <span class="label label-two">-15%</span>
                                                        <span class="label label-new">New</span> -->
                                                        <div class="product-item-action">
                                                            <button type="button" class="product-action-btn action-btn-quick-view" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#action-QuickViewModal">
                                                                <i class="icon-magnifier"></i>
                                                            </button>
                                                            <button type="button" class="product-action-btn action-btn-wishlist" data-tooltip-text="Add to wishlist" data-bs-toggle="modal" data-bs-target="#action-WishlistModal">
                                                                <i class="icon-heart"></i>
                                                            </button>
                                                            <button type="button" class="product-action-btn action-btn-compare" data-tooltip-text="Compare" data-bs-toggle="modal" data-bs-target="#action-CompareModal">
                                                                <i class="icon-refresh"></i>
                                                            </button>
                                                            <button type="button" class="product-action-btn action-btn-cart" data-tooltip-text="Add to cart" data-bs-toggle="modal" data-bs-target="#action-CartAddModal">
                                                                <i class="icon-bag"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="product-item-info">
                                                        <h5 class="product-item-title"><a href="shop-single-product.html">{{index+1}}.{{ product.name}}</a></h5>
                                                        <div class="product-item-price" v-if="product.sale.hasPromotion"><span class="price-old">${{product.price}}</span>${{ promotionCalcul(product.price,product.sale.percentageOfPromotion) }}</div>
                                                        <div class="product-item-price" v-else>${{Number.parseFloat(product.price)}}</div>
                                                        <div class="product-item-review-icon">
                                                            <img src="assets/images/icons/star.svg" width="17" height="17" alt="icon-star">
                                                            <img src="assets/images/icons/star.svg" width="17" height="17" alt="icon-star">
                                                            <img src="assets/images/icons/star.svg" width="17" height="17" alt="icon-star">
                                                            <img src="assets/images/icons/star.svg" width="17" height="17" alt="icon-star">
                                                            <img src="assets/images/icons/star.svg" width="17" height="17" alt="icon-star">
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--== End Product Item ==-->
                                            </div>                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
    </div>
</template>

<script>
export default {
name:"productTabArea",
data(){
    return {
        newProducts:[
            {
                sale:{
                    hasPromotion:true,
                    percentageOfPromotion:10,
                },
                name:"produit test",
                price:100,
                currentImage:'./assets/images/homeProducts/productTest.png',
                images:[
                    './assets/images/homeProducts/productTest.png',
                    './assets/images/homeProducts/productTest2.png',
                ], 
            },
            {
                sale:{
                    hasPromotion:true,
                    percentageOfPromotion:10,
                },
                name:"produit test",
                price:100,
                currentImage:'./assets/images/homeProducts/productTest.png',
                images:[
                    './assets/images/homeProducts/productTest.png',
                    './assets/images/homeProducts/productTest2.png',
                ], 
            },
            {
                sale:{
                    hasPromotion:true,
                    percentageOfPromotion:10,
                },
                name:"produit test",
                price:100,
                currentImage:'./assets/images/homeProducts/productTest.png',
                images:[
                    './assets/images/homeProducts/productTest.png',
                    './assets/images/homeProducts/productTest2.png',
                ], 
            },
            {
                sale:{
                    hasPromotion:true,
                    percentageOfPromotion:10,
                },
                name:"produit test",
                price:100,
                currentImage:'./assets/images/homeProducts/productTest.png',
                images:[
                    './assets/images/homeProducts/productTest.png',
                    './assets/images/homeProducts/productTest2.png',
                ], 
            },
            {
                sale:{
                    hasPromotion:true,
                    percentageOfPromotion:10,
                },
                name:"produit test",
                price:100,
                currentImage:'./assets/images/homeProducts/productTest.png',
                images:[
                    './assets/images/homeProducts/productTest.png',
                    './assets/images/homeProducts/productTest2.png',
                ], 
            },
            {
                sale:{
                    hasPromotion:true,
                    percentageOfPromotion:10,
                },
                name:"produit test",
                price:100,
                currentImage:'./assets/images/homeProducts/productTest.png',
                images:[
                    './assets/images/homeProducts/productTest.png',
                    './assets/images/homeProducts/productTest2.png',
                ], 
            },
            {
                sale:{
                    hasPromotion:false,
                    percentageOfPromotion:10,
                },
                name:"produit test 2",
                price:200,
                currentImage:'./assets/images/homeProducts/productTest3.png',
                images:[
                    './assets/images/homeProducts/productTest3.png',
                    './assets/images/homeProducts/productTest4.png',
                    './assets/images/homeProducts/productTest5.png',
                ], 
            },
            {
                sale:{
                    hasPromotion:false,
                    percentageOfPromotion:10,
                },
                name:"produit test 2",
                price:200,
                currentImage:'./assets/images/homeProducts/productTest3.png',
                images:[
                    './assets/images/homeProducts/productTest3.png',
                    './assets/images/homeProducts/productTest4.png',
                    './assets/images/homeProducts/productTest5.png',
                ], 
            },
            {
                sale:{
                    hasPromotion:false,
                    percentageOfPromotion:10,
                },
                name:"produit test 2",
                price:200,
                currentImage:'./assets/images/homeProducts/productTest3.png',
                images:[
                    './assets/images/homeProducts/productTest3.png',
                    './assets/images/homeProducts/productTest4.png',
                    './assets/images/homeProducts/productTest5.png',
                ], 
            }
        ]
        ,
        showedProducts:[]
    }
},
mounted(){
    this.showedProducts=this.newProducts;
},
methods:{
    promotionCalcul(number,prom){
        let result=number - ((prom * number)/100);
        return result;
    },
    arraylength(array){
        if(array.length>1){
            return true
        }
        return false;
    }

}}
</script>

<style scoped>
.swiper-slide{
    width:268px !important;
    margin-right:30px
}
.product-item-review-icon>img{
    width:17px !important;
    height:17px !important;
}

.swiper-wrapper::-webkit-scrollbar{
    display:none;
}
</style>