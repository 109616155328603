<template>
          <!--== Start Sidebar Cart Menu ==-->
          <aside class="sidebar-cart-modal offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasWithCartSidebar">
            <div class="offcanvas-header">
                <button type="button" class="btn-close cart-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="sidebar-cart-inner offcanvas-body">
                <div class="sidebar-cart-content">
                    <div class="sidebar-cart-all">
                        <div class="cart-header">
                            <h3>Shopping Cart</h3>
                            <div class="close-style-wrap">
                                <button type="button" class="btn-close cart-close" data-bs-dismiss="offcanvas" aria-label="Close"><i class="icon-close"></i></button>
                            </div>
                        </div>
                        <div class="cart-content cart-content-padding">
                            <ul>
                                <li class="single-product-cart">
                                    <div class="cart-img">
                                        <a href="shop-single-product.html"><img src="assets/images/shop/details/single-product-thumb-1.jpg" alt="Image" width="70" height="82"></a>
                                    </div>
                                    <div class="cart-title">
                                        <h4><a href="shop-single-product.html">Product with video</a></h4>
                                        <span> 1 × <span class="price"> $12.00 </span></span>
                                    </div>
                                    <div class="cart-delete">
                                        <a href="#/"><i class="pe-7s-trash icons"></i></a>
                                    </div>
                                </li>
                                <li class="single-product-cart">
                                    <div class="cart-img">
                                        <a href="shop-single-product.html"><img src="assets/images/shop/details/single-product-thumb-2.jpg" alt="Image" width="70" height="82"></a>
                                    </div>
                                    <div class="cart-title">
                                        <h4><a href="shop-single-product.html">Product with large title</a></h4>
                                        <span> 1 × <span class="price"> $59.90 </span></span>
                                    </div>
                                    <div class="cart-delete">
                                        <a href="#/"><i class="pe-7s-trash icons"></i></a>
                                    </div>
                                </li>
                            </ul>
                            <div class="cart-total">
                                <h4>Subtotal: <span>$71.90</span></h4>
                            </div>
                            <div class="cart-checkout-btn">
                                <a class="cart-btn" href="shop-cart.html">view cart</a>
                                <a class="checkout-btn" href="shop-checkout.html">checkout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        <!--== End Sidebar Cart Menu ==-->
</template>

<script>
export default {
name:"sidebarCart"
}
</script>

<style>

</style>