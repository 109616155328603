<template>
            <div class="blog-area section-space">
                <div class="container">
                    <div class="section-title text-center">
                        <h5 class="sub-title text-uppercase text-primary mt-n1">Featured Blogs</h5>
                        <h2 class="title mb-0 mt-n2">Latest Blog</h2>
                    </div>
                    <div class="swiper-container-wrap swiper-button-style swiper-button-nav5">
                        <div class="swiper post-items-slider">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <!--== Start Blog Item ==-->
                                    <div class="post-item">
                                        <a href="blog-details.html" class="post-item-thumb">
                                            <img class="w-100" src="assets/images/blog/1.jpg" width="348" height="232" alt="Image-HasTech">
                                        </a>
                                        <div class="post-item-content">
                                            <h3 class="post-item-title"><a href="blog-details.html">Decorate your simple steps</a></h3>
                                            <div class="post-item-meta">
                                                <div class="post-item-date"><a href="blog.html"> 17 January, 2023</a></div>
                                                <div class="post-item-comments"><a href="blog-details.html"> 3 comments</a></div>
                                            </div>
                                            <p class="post-item-desc">The summer holidays are wonderful. Dressing for them can be significantly less so: Packing light...</p>
                                            <a class="btn btn-gray-border" href="blog-details.html">Read More</a>
                                        </div>
                                    </div>
                                    <!--== End Blog Item ==-->
                                </div>

                                <div class="swiper-slide">
                                    <!--== Start Blog Item ==-->
                                    <div class="post-item">
                                        <a href="blog-details.html" class="post-item-thumb">
                                            <img class="w-100" src="assets/images/blog/2.jpg" width="348" height="232" alt="Image-HasTech">
                                        </a>
                                        <div class="post-item-content">
                                            <h3 class="post-item-title"><a href="blog-details.html">Living Room Designing Ideas</a></h3>
                                            <div class="post-item-meta">
                                                <div class="post-item-date"><a href="blog.html"> 16 January, 2023</a></div>
                                                <div class="post-item-comments"><a href="blog-details.html"> 5 comments</a></div>
                                            </div>
                                            <p class="post-item-desc">The summer holidays are wonderful. Dressing for them can be significantly less so: Packing light...</p>
                                            <a class="btn btn-gray-border" href="blog-details.html">Read More</a>
                                        </div>
                                    </div>
                                    <!--== End Blog Item ==-->
                                </div>

                                <div class="swiper-slide">
                                    <!--== Start Blog Item ==-->
                                    <div class="post-item">
                                        <a href="blog-details.html" class="post-item-thumb">
                                            <img class="w-100" src="assets/images/blog/3.jpg" width="348" height="232" alt="Image-HasTech">
                                        </a>
                                        <div class="post-item-content">
                                            <h3 class="post-item-title"><a href="blog-details.html">Plan to Design your Interior</a></h3>
                                            <div class="post-item-meta">
                                                <div class="post-item-date"><a href="blog.html"> 17 January, 2023</a></div>
                                                <div class="post-item-comments"><a href="blog-details.html"> 0 comments</a></div>
                                            </div>
                                            <p class="post-item-desc">The summer holidays are wonderful. Dressing for them can be significantly less so: Packing light...</p>
                                            <a class="btn btn-gray-border" href="blog-details.html">Read More</a>
                                        </div>
                                    </div>
                                    <!--== End Blog Item ==-->
                                </div>

                                <div class="swiper-slide">
                                    <!--== Start Blog Item ==-->
                                    <div class="post-item">
                                        <a href="blog-details.html" class="post-item-thumb">
                                            <img class="w-100" src="assets/images/blog/4.jpg" width="348" height="232" alt="Image-HasTech">
                                        </a>
                                        <div class="post-item-content">
                                            <h3 class="post-item-title"><a href="blog-details.html">Nullam at tellus vehicula</a></h3>
                                            <div class="post-item-meta">
                                                <div class="post-item-date"><a href="blog.html"> 16 January, 2023</a></div>
                                                <div class="post-item-comments"><a href="blog-details.html"> 9 comments</a></div>
                                            </div>
                                            <p class="post-item-desc">The summer holidays are wonderful. Dressing for them can be significantly less so: Packing light...</p>
                                            <a class="btn btn-gray-border" href="blog-details.html">Read More</a>
                                        </div>
                                    </div>
                                    <!--== End Blog Item ==-->
                                </div>

                                <div class="swiper-slide">
                                    <!--== Start Blog Item ==-->
                                    <div class="post-item">
                                        <a href="blog-details.html" class="post-item-thumb">
                                            <img class="w-100" src="assets/images/blog/5.jpg" width="348" height="232" alt="Image-HasTech">
                                        </a>
                                        <div class="post-item-content">
                                            <h3 class="post-item-title"><a href="blog-details.html">Summer Classics in Positano</a></h3>
                                            <div class="post-item-meta">
                                                <div class="post-item-date"><a href="blog.html"> 17 January, 2023</a></div>
                                                <div class="post-item-comments"><a href="blog-details.html"> 3 comments</a></div>
                                            </div>
                                            <p class="post-item-desc">The summer holidays are wonderful. Dressing for them can be significantly less so: Packing light...</p>
                                            <a class="btn btn-gray-border" href="blog-details.html">Read More</a>
                                        </div>
                                    </div>
                                    <!--== End Blog Item ==-->
                                </div>

                                <div class="swiper-slide">
                                    <!--== Start Blog Item ==-->
                                    <div class="post-item">
                                        <a href="blog-details.html" class="post-item-thumb">
                                            <img class="w-100" src="assets/images/blog/6.jpg" width="348" height="232" alt="Image-HasTech">
                                        </a>
                                        <div class="post-item-content">
                                            <h3 class="post-item-title"><a href="blog-details.html">Photography Tips From Wai Su</a></h3>
                                            <div class="post-item-meta">
                                                <div class="post-item-date"><a href="blog.html"> 16 January, 2023</a></div>
                                                <div class="post-item-comments"><a href="blog-details.html"> 8 comments</a></div>
                                            </div>
                                            <p class="post-item-desc">The summer holidays are wonderful. Dressing for them can be significantly less so: Packing light...</p>
                                            <a class="btn btn-gray-border" href="blog-details.html">Read More</a>
                                        </div>
                                    </div>
                                    <!--== End Blog Item ==-->
                                </div>

                                <div class="swiper-slide">
                                    <!--== Start Blog Item ==-->
                                    <div class="post-item">
                                        <a href="blog-details.html" class="post-item-thumb">
                                            <img class="w-100" src="assets/images/blog/7.jpg" width="348" height="232" alt="Image-HasTech">
                                        </a>
                                        <div class="post-item-content">
                                            <h3 class="post-item-title"><a href="blog-details.html">Glance Into the Future of Androids</a></h3>
                                            <div class="post-item-meta">
                                                <div class="post-item-date"><a href="blog.html"> 17 January, 2023</a></div>
                                                <div class="post-item-comments"><a href="blog-details.html"> 0 comments</a></div>
                                            </div>
                                            <p class="post-item-desc">The summer holidays are wonderful. Dressing for them can be significantly less so: Packing light...</p>
                                            <a class="btn btn-gray-border" href="blog-details.html">Read More</a>
                                        </div>
                                    </div>
                                    <!--== End Blog Item ==-->
                                </div>
                            </div>
                        </div>

                        <!--== Start Swiper Navigation ==-->
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </div>
            </div>
</template>

<script>
export default {
name:"blogAreaComponent"
}
</script>

<style>

</style>