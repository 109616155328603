<template>
          <main class="main-content">
              <slider-component></slider-component>
              <product-banner></product-banner>
              <product-tab-area></product-tab-area>
              <blog-area-component></blog-area-component>
          </main>
</template>

<script>
import SliderComponent from '@/components/Home/SliderComponent.vue'
import ProductBanner from '@/components/Home/ProductBannerComponent.vue'
import productTabArea from '@/components/Home/productTabArea.vue'
import blogAreaComponent from '@/components/Home/blogAreaComponent.vue'

export default {
  name: 'HomeView',
  components: {
    SliderComponent,
    ProductBanner,
    productTabArea,
    blogAreaComponent
  },
mounted() {
    this.loadExternalScripts([
      './assets/js/main.js',
    ])
      .then(() => {
        if (window.ExternalPlugin1) {
          // Use the external plugins here
          this.message = 'External plugins loaded successfully!';
          window.ExternalPlugin1.initialize(); // Example function call
        } else {
          this.message = 'Failed to load some external plugins.';
        }
      })
      .catch(error => {
        console.error('Error loading external scripts:', error);
        this.message = 'Error loading external scripts.';
      });
  },
methods: {
    loadExternalScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script ${src}`));
        document.head.appendChild(script);
      });
    },
    loadExternalScripts(scripts) {
      return Promise.all(scripts.map(this.loadExternalScript));
    }

}}
</script>
