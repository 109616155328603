<template>
            <!--== Start Hero Area Wrapper ==-->
            <div class="hero-slider-area position-relative">
                <div class="swiper hero-slider-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide hero-slide-item"
                        v-for="slider of sliders" :key="slider"
                        >
                            <div class="container h-100">
                                <div class="row align-items-center position-relative h-100">
                                    <div class="col-lg-6 col-md-7 col-sm-12 col-12">
                                        <div class="hero-slide-content mb-3">
                                            <h3 class="hero-slide-sub-title">{{slider.header1}}</h3>
                                            <h1 class="hero-slide-title">{{slider.header2}}</h1>
                                            <p class="hero-slide-desc">{{ slider.p }}</p>
                                            <div class="btn-wrp d-flex align-items-center justify-content-center justify-content-md-start">
                                                <a class="btn btn-secondary" href="shop.html"> Explore More <i class="icon icofont-cart-alt"></i></a>
                                                <div class="btn-play-wrp ms-4 ms-lg-8"><a class="btn-play ht-popup-video" data-fancybox data-type="iframe" href="https://www.youtube.com/watch?v=_9VUPq3SxOc"> <i class="icon icofont-play"></i></a> View Video</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-5 col-sm-12 col-12">
                                        <div class="hero-slide-thumb">
                                            <img :src="slider.link" width="570" height="474" alt="Image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--== Add Pagination ==-->
                    <div class="hero-slide-one-pagination"></div>
                    <!--== Start Swiper Navigation ==-->
                    <div class="swiper-button-prev swiper-button-two"></div>
                    <div class="swiper-button-next swiper-button-two"></div>
                </div>
            </div>
            <!--== End Hero Area Wrapper ==-->
</template>

<script>
import gsap from 'gsap'


export default {
        name:"SliderComponent",
        data(){
            return{
                interval:null,
                currentIndex:0,
                sliders:[
                   {
                    header1:"Transform Your Space",
                    header2:"Deco Store",
                    p:"Where Every Corner Becomes a Story. Crafted for Comfort, Designed for Delight",
                    link:'assets/images/slider/slider1-1.png'
                   } ,
                   {
                    header1:"text2",
                    header2:"Deco Store",
                    p:"Where Every Corner Becomes a Story. Crafted for Comfort, Designed for Delight",
                    link:'assets/images/slider/slider1-3.png'
                   }
                    ]
                }
            },
            methods:{
                next(){
                    this.currentIndex ++
                    this.currentIndex=this.currentIndex % this.sliders.length

                    gsap.fromTo('.container-slider',{
                    xPercent:-50,
                    opacity:0,
                    scale:0.5,
                },{
                    xPercent:0,
                    opacity:1,
                    scale:1,
                    duration:2,
                })
                },
                prevent(){
                    if(this.currentIndex==0) this.currentIndex=this.sliders.length - 1
                    else this.currentIndex --

                    gsap.fromTo('.container-slider',{
                    xPercent:-50,
                    opacity:0,
                    scale:0.5,
                },{
                    xPercent:0,
                    opacity:1,
                    scale:1,
                    duration:2,
                })
                }
            }
            ,
            mounted(){
                gsap.fromTo('.container-slider',{
                    xPercent:-50,
                    opacity:0,
                    scale:0.5,
                },{
                    xPercent:0,
                    opacity:1,
                    scale:1,
                    duration:2,
                })

               this.interval=setInterval(()=>{
                    this.prevent()
                },10000)
            },
           unmounted(){
            clearInterval(this.interval)
           }
            ,

            
        }

</script>

<style scoped>


</style>

