<template>
            <aside class="aside-side-menu-wrapper off-canvas-area offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions">
            <div class="offcanvas-header" data-bs-dismiss="offcanvas">
                <button type="button" class="btn-close"><i class="icon icon-close"></i></button>
            </div>
            <div class="offcanvas-body">
                <div class="aside-search-form">
                    <form action="#" method="post">
                        <input id="search-input2" type="search" class="form-control" placeholder="Search our store">
                        <button class="search-button" type="button"><i class="icon-magnifier"></i></button>
                    </form>
                </div>
                <!-- Start Mobile Menu Wrapper -->
                <div class="res-mobile-menu">
                    <nav id="offcanvasNav" class="offcanvas-menu">
                        <ul>
                            <li><a href="javascript:void(0)">Home</a>
                                <ul>
                                    <li><a href="index.html">Home One</a></li>
                                    <li><a href="index-two.html">Home Two</a></li>
                                    <li><a href="index-three.html">Home Three</a></li>
                                    <li><a href="index-four.html">Home Four</a></li>
                                </ul>
                            </li>
                            <li><a href="javascript:void(0)">Shop</a>
                                <ul>
                                    <li><a href="javascript:void(0)">Shop Layout</a>
                                        <ul>
                                            <li><a href="shop-three-columns.html">Shop 3 Column</a></li>
                                            <li><a href="shop-four-columns.html">Shop 4 Column</a></li>
                                            <li><a href="shop.html">Shop Left Sidebar</a></li>
                                            <li><a href="shop-right-sidebar.html">Shop Right Sidebar</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="javascript:void(0)">Single Product</a>
                                        <ul>
                                            <li><a href="shop-single-product.html">Single Product Normal</a></li>
                                            <li><a href="shop-single-product-sticky-content.html">Product Sticky Content</a></li>
                                            <li><a href="shop-single-product-thumbnail-right.html">Product Thumbnail Right</a></li>
                                            <li><a href="shop-single-product-gallery.html">Single Product Gallery</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="javascript:void(0)">Others Pages</a>
                                        <ul>
                                            <li><a href="shop-cart.html">Shopping Cart</a></li>
                                            <li><a href="shop-checkout.html">Checkout</a></li>
                                            <li><a href="shop-wishlist.html">Wishlist</a></li>
                                            <li><a href="shop-compare.html">Compare</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="javascript:void(0)">Pages</a>
                                <ul>
                                    <li><a href="about-us.html">About</a></li>
                                    <li><a href="account.html">Account</a></li>
                                    <li><a href="faq.html">Faq</a></li>
                                    <li><a href="page-not-found.html">Page Not Found</a></li>
                                </ul>
                            </li>
                            <li><a href="javascript:void(0)">Blog</a>
                                <ul>
                                    <li><a href="blog.html">Blog Grid</a></li>
                                    <li><a href="blog-left-sidebar.html">Blog Left Sidebar</a></li>
                                    <li><a href="blog-right-sidebar.html">Blog Right Sidebar</a></li>
                                    <li><a href="blog-details.html">Blog Details</a></li>
                                    <li><a href="blog-details-left-sidebar.html">Blog Details Left Sidebar</a></li>
                                    <li><a href="blog-details-right-sidebar.html">Blog Details Right Sidebar</a></li>
                                </ul>
                            </li>
                            <li><a href="contact.html">Contact</a></li>
                        </ul>
                    </nav>
                </div>
                <!-- End Mobile Menu Wrapper -->

                <nav id="offcanvasNav2" class="offcanvas-menu offcanvas-menu-two">
                    <ul>
                        <li><a href="javascript:void(0)">Currency</a>
                            <ul>
                                <li><a href="javascript:void(0)">USD - US Dollar</a></li>
                                <li><a href="javascript:void(0)">EUR - Euro</a></li>
                                <li><a href="javascript:void(0)">GBP - British Pound</a></li>
                            </ul>
                        </li>
                        <li><a href="javascript:void(0)">Account</a>
                            <ul>
                                <li><a href="login-register.html">Login</a></li>
                                <li><a href="login-register.html">Register</a></li>
                                <li><a href="account.html">My Account</a></li>
                            </ul>
                        </li>
                    </ul>
                </nav>

            </div>
        </aside>
</template>

<script>
export default {
name:"AsideComponent"
}
</script>

<style>

</style>