<template>
  <div class="wrapper">
      <navBar/>
      <router-view/>
      <footerComponent/>
      <div class="scroll-to-top"><span class="icon-arrow-up"></span></div>
      <product-quick-wishlist></product-quick-wishlist>
      <productQuickAddTwo></productQuickAddTwo>
      <product-quick-add></product-quick-add>
      <sidebar-cart></sidebar-cart>
      <aside-search></aside-search>
      <aside-component></aside-component>
  </div>
</template>


<script>
import footerComponent from './components/footerComponent.vue';
import navBar from './components/navbarComponent.vue'
import AsideComponent from './components/AsideComponent.vue';
import AsideSearch from './components/AsideSearch.vue';
import sidebarCart from './components/sidebarCart.vue';
import ProductQuickAdd from './components/ProductQuickAdd.vue';
import productQuickAddTwo from './components/productQuickAddTwo.vue';
import ProductQuickWishlist from './components/ProductQuickWishlist.vue';


export default {
  name:"App",
  components:{
    navBar,
    footerComponent,
    AsideComponent,
    AsideSearch,
    sidebarCart,
    ProductQuickAdd,
    productQuickAddTwo,
    ProductQuickWishlist
    
  },
  mounted() {
    this.loadExternalScripts([
      './assets/js/main.js',
    ])
      .then(() => {
        if (window.ExternalPlugin1) {
          // Use the external plugins here
          this.message = 'External plugins loaded successfully!';
          window.ExternalPlugin1.initialize(); // Example function call
        } else {
          this.message = 'Failed to load some external plugins.';
        }
      })
      .catch(error => {
        console.error('Error loading external scripts:', error);
        this.message = 'Error loading external scripts.';
      });
  },
  methods: {
    loadExternalScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script ${src}`));
        document.head.appendChild(script);
      });
    },
    loadExternalScripts(scripts) {
      return Promise.all(scripts.map(this.loadExternalScript));
    }
  }}
</script>

<style>
</style>
